import PropTypes from 'prop-types';

export const AUTHENTIFICATION_USECASE = {
  CONFIGURABLE_TAB: 'configurableTab',
  CONFIGURABLE_TAB_CONFIG: 'configurableTabConfig',
  CONFIGURABLE_TAB_DELETION: 'configurableTabDeletion',
  CONNECTOR_CONFIG: 'connectorConfig',
  PERSONAL_TAB: 'personalTab',
  SILENTLY_HIDE: 'silentlyHide'
};

export const authentificationUsecaseType = PropTypes.oneOf([
  AUTHENTIFICATION_USECASE.CONFIGURABLE_TAB,
  AUTHENTIFICATION_USECASE.CONFIGURABLE_TAB_CONFIG,
  AUTHENTIFICATION_USECASE.CONFIGURABLE_TAB_DELETION,
  AUTHENTIFICATION_USECASE.CONNECTOR_CONFIG,
  AUTHENTIFICATION_USECASE.PERSONAL_TAB,
  AUTHENTIFICATION_USECASE.SILENTLY_HIDE]).isRequired;
