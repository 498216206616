import React from 'react';
import PropTypes from 'prop-types';

import { Text } from '@fluentui/react-northstar';

import styling from './styling';

const propTypes = {
  small: PropTypes.bool,
  text: PropTypes.string,
  styles: PropTypes.object
}

export function PageTitle({small = false, text = null, styles = {}, theme = 'default'}) {
  if (text) {
    return (
      <Text as="h1" styles={{
        ...styles,
        fontFamily: styling.font.title,
        fontSize: small ? '1.4em' : '2em',
        color: (theme === 'default') ? styling.color.tonic.dark : styling.color.tonic.light,
        marginBottom: small ? undefined : '0.3em',
        marginTop: 0
      }}>
        {text}
      </Text>
    );
  }

  return null;
}

PageTitle.propTypes = propTypes;
