import superagent from 'superagent';

import createAsyncAction from '@cocoom/cocoom-front-utils/api/createAsyncAction';
import createAsyncActionCreator from '@cocoom/cocoom-front-utils/api/createAsyncActionCreator';


/*
 * actions
 */
export const CHECK_AUTHENTIFICATION_ACTION_ID = '/cocoom/auth/CHECK_AUTHENTIFICATION_ACTION_ID';
const CHECK_AUTHENTIFICATION = createAsyncAction(CHECK_AUTHENTIFICATION_ACTION_ID);

export const GET_ASSOCIATED_COCOOM_ACCOUNTS_ACTION_ID = '/cocoom/auth/GET_ASSOCIATED_COCOOM_ACCOUNTS_ACTION_ID';
const GET_ASSOCIATED_COCOOM_ACCOUNTS = createAsyncAction(GET_ASSOCIATED_COCOOM_ACCOUNTS_ACTION_ID);

export const SIGNOUT_ACTION_ID = '/cocoom/auth/SIGNOUT_ACTION_ID';
const SIGNOUT = createAsyncAction(SIGNOUT_ACTION_ID);

/*
 * action creators
 */
export function checkAuthentification() {
  return createAsyncActionCreator(CHECK_AUTHENTIFICATION, async () => {
    const result = await superagent.get(`/api/msteams/auth/getauth`);
    if (!result.body) {
      throw new Error('unexpected response payload');
    }

    const res = {user: {...result.body.user}};
    res.user.isInvited = result.body.invited;
    return res;
  });
}

export function getAssociatedCocoomAccounts({azureTenantId, azureUserEmail}) {
  if (!azureTenantId) {
    throw new Error('"azureTenantId" argument cannot be null, undefined, or emtpy');
  }

  return createAsyncActionCreator(GET_ASSOCIATED_COCOOM_ACCOUNTS, async () => {
    let userEmailQueryPart = '';
    if (azureUserEmail) {
      userEmailQueryPart = `u=${encodeURIComponent(azureUserEmail)}&`;
    }
    const result = await superagent.get(`/api/msteams/tenant/${azureTenantId}/accounts?${userEmailQueryPart}authmethodcheck=true`);
    if (!result.body) {
      throw new Error('unexpected response payload');
    }

    return {
      accounts: result.body.accounts,
      homogeneous: result.body.homogeneous,
      allaadv2: result.body.allaadv2
    };
  });
}

export function signOut() {
  return createAsyncActionCreator(SIGNOUT, async () => {
    const result = await superagent.get(`/api/msteams/auth/signout`);
    if (!result.body) {
      throw new Error('unexpected response payload');
    }
  });
}

/*
 * initial state
 */
const initialState = {
  action: null,
  actionError: null,
  actionState: 'idle',
  user: null,
  isInvited: null,
  isUserAuthentified: null,
  userRoles: null,
  associatedCocoomAccount: undefined,
  associatedCocoomAccounts: [],
  isAuthHomogeneousAccrossAccounts: false
}

/*
 * reducer
 */
export default function reducer(state = initialState, action) {
  switch (action.type) {

    ////
    //  CHECK_AUTHENTIFICATION
    case CHECK_AUTHENTIFICATION.PENDING:
      return {
        ...state,
        isInvited: null,
        isUserAuthentified: null,
        userRoles: null,
        action: CHECK_AUTHENTIFICATION_ACTION_ID,
        actionState: 'working',
        actionError: null
      };

    case CHECK_AUTHENTIFICATION.DONE:
      return {
        ...state,
        user: action.user,
        isInvited: action.user.isInvited,
        isUserAuthentified: true,
        userRoles: action.user.roles,
        actionState: 'done'
      };

    case CHECK_AUTHENTIFICATION.FAILED:
      return {
        ...state,
        user: null,
        actionState: 'failed',
        isUserAuthentified: false,
        actionError: action.error
      };

    ////
    //  GET_ASSOCIATED_COCOOM_ACCOUNTS
    case GET_ASSOCIATED_COCOOM_ACCOUNTS.PENDING:
      return {
        ...state,
        associatedCocoomAccount: undefined,
        associatedCocoomAccounts: [],
        action: GET_ASSOCIATED_COCOOM_ACCOUNTS_ACTION_ID,
        actionState: 'working',
        actionError: null
      };

    case GET_ASSOCIATED_COCOOM_ACCOUNTS.DONE:
      const newState = {
        ...state,
        actionState: 'done'
      };

      if (Array.isArray(action.accounts)) {
        if (action.accounts.length > 0) {
          newState.associatedCocoomAccount = action.accounts[0];
          newState.associatedCocoomAccounts = action.accounts;
        } else {
          newState.associatedCocoomAccount = null;
          newState.associatedCocoomAccounts = [];
        }

        newState.isAuthHomogeneousAccrossAccounts = action.homogeneous;
      } else {
        newState.actionState = 'failed';
        newState.actionError = 'response data must be an array';
      }

      return newState;

    case GET_ASSOCIATED_COCOOM_ACCOUNTS.FAILED:
      return {
        ...state,
        actionState: 'failed',
        actionError: action.error,
        isAuthHomogeneousAccrossAccounts: false
      };

    ////
    //  SIGNOUT
    case SIGNOUT.PENDING:
      return {
        ...state,
        action: SIGNOUT_ACTION_ID,
        actionState: 'working',
        actionError: null
      };

    case SIGNOUT.DONE:
      return {
        ...state,
        user: null,
        isInvited: null,
        isUserAuthentified: null,
        userRoles: null,
        actionState: 'done'
      };

    case SIGNOUT.FAILED:
      return {
        ...state,
        user: null,
        actionState: 'failed',
        actionError: action.error
      };

    ////
    //  default
    default:
      return state;
  }
}
