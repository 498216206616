import superagent from 'superagent';
import i18n from 'i18next';

import handleSuperagentError from '@cocoom/cocoom-front-utils/api/handleSuperagentError';
import createAsyncActionCreator from '@cocoom/cocoom-front-utils/api/createAsyncActionCreator';


/*
 * actions
 */
 const GET_USER_ACCOUNTS = { 
  PENDING: '/cocoom/GET_USER_ACCOUNTS_PENDING',
  DONE: '/cocoom/GET_USER_ACCOUNTS_DONE',
  FAILED: '/cocoom/GET_USER_ACCOUNTS_FAILED'
};

const GET_ACCOUNT_CHANNELS = { 
  PENDING: '/cocoom/GET_ACCOUNT_CHANNELS_PENDING',
  DONE: '/cocoom/GET_ACCOUNT_CHANNELS_DONE',
  FAILED: '/cocoom/GET_ACCOUNT_CHANNELS_FAILED'
};

const CREATE_MSTEAMS_TAB_ACTION_ID = '/cocoom/CREATE_MSTEAMS_TAB_ACTION_ID';
const DELETE_MSTEAMS_TAB_ACTION_ID = '/cocoom/DELETE_MSTEAMS_TAB_ACTION_ID';
const CREATE_MSTEAMS_CONNECTOR_ACTION_ID = '/cocoom/DELETE_MSTEAMS_TAB_ACTION_ID';
export const GET_USER_ACCOUNTS_ACTION_ID = '/cocoom/GET_USER_ACCOUNTS_ACTION_ID';
export const GET_ACCOUNT_CHANNELS_ACTION_ID = '/cocoom/GET_ACCOUNT_CHANNELS_ACTION_ID';

const SET_MSTEAMS_CONTEXT_TYPE = '/ui/SET_MSTEAMS_CONTEXT_TYPE';
const SET_MSTEAMS_THEME = '/ui/SET_MSTEAMS_THEME';

/*
 * action creators
 */
export function registerConfigurableTabCreator(microsoftTeams, msteamsContext, tabSettings, cocoomAccountId, cocoomChannelRef) {
  microsoftTeams.settings.registerOnSaveHandler(async (saveEvent) => {
    microsoftTeams.settings.setSettings(tabSettings);
    try {
      await superagent.post(`/api/msteams/tab`).send({
        azureTenantId: msteamsContext.tid,
        groupId: msteamsContext.groupId,
        teamId: msteamsContext.teamId,
        teamName: msteamsContext.teamName,
        teamsChannelId: msteamsContext.channelId,
        userId: msteamsContext.userObjectId,
        userEmail: msteamsContext.loginHint,
        cocoomAccountId: cocoomAccountId,
        cocoomChannelRef: cocoomChannelRef,
        entityId: tabSettings.entityId,
        entityLabel: tabSettings.suggestedDisplayName,
        entityWebUrl: tabSettings.contentUrl // TODO: replace with the right URL (e.g. without 'embed'. But we need to know if cocom channel is in global nav or not.)
      });
      saveEvent.notifySuccess();
    } catch(error) {
      const err = handleSuperagentError(error);
      let text = err.text;
      switch(err.statusCode) {
        case 409:
          text = i18n.t('errors.tabAlreadyExistsHint');
          break;
        default:
          break;
      }
      saveEvent.notifyFailure(`${err.statusCode} ${text}`);
    }
  });

  return {type: CREATE_MSTEAMS_TAB_ACTION_ID};
}

export function registerConfigurableTabDestructor(microsoftTeams, msteamsContext) {
  microsoftTeams.settings.registerOnRemoveHandler((removeEvent) => {
    microsoftTeams.settings.getSettings(async (settings) => {
      try {
        await superagent.del(`/api/msteams/tab/${settings.entityId}`)
          .set('azureTenantId', msteamsContext.tid)
          .set('teamId', msteamsContext.teamId)
          .set('groupId', msteamsContext.groupId)
          .set('teamsChannelId', msteamsContext.channelId)
          .set('cocoomAccountId', 'foobar');

        removeEvent.notifySuccess();
      } catch(error) {
        const err = handleSuperagentError(error);
        removeEvent.notifyFailure(`${err.statusCode} ${err.text}`);
      }
    });
  });

  return {type: DELETE_MSTEAMS_TAB_ACTION_ID};
}

export function registerConnectorCreator(microsoftTeams, msteamsContext, connectorSettings, cocoomAccountId, cocoomChannelRef) {
  microsoftTeams.settings.registerOnSaveHandler((saveEvent) => {
    microsoftTeams.settings.setSettings(connectorSettings);
    microsoftTeams.settings.getSettings(settings => {
      const webhookUrl = settings.webhookUrl;
      console.log('webhookUrl=', webhookUrl);
      saveEvent.notifySuccess();
    });
  });

  return {type: CREATE_MSTEAMS_CONNECTOR_ACTION_ID};
}

export function getAccountChannels(accountRef) {
  if (accountRef) {
    return createAsyncActionCreator(GET_ACCOUNT_CHANNELS, async () => {
      const result = await superagent.get(`/api/msteams/account/${accountRef}/channels`);
      if (!result.body || !Array.isArray(result.body)) {
        throw new Error('unexpected response payload');
      }
      return { channels: result.body};
    });
  }

  throw new Error(`'accountRef' argument is mandatory. Please provided a value.`);
}

export function getUserAccounts() {
  return createAsyncActionCreator(GET_USER_ACCOUNTS, async () => {
    const result = await superagent.get(`/api/msteams/user/accounts`);
    if (!result.body || !Array.isArray(result.body)) {
      throw new Error('unexpected response payload');
    }
    return { accounts: result.body};
  });
}

export function setMsTeamsContext(context) {
  return {type: SET_MSTEAMS_CONTEXT_TYPE, context: context};
}

export function setMsTeamsTheme(theme) {
  return {type: SET_MSTEAMS_THEME, theme: theme};
}

/*
 * initial state
 */
const initialState = {
  action: null,
  actionState: 'idle',
  actionError: null,
  msteamsContext: {},
  userAccounts: null,
  accountChannels: null,
  msteamsTheme: 'default'
}

/*
 * reducer
 */
export default function reducer(state = initialState, action) {
  switch (action.type) {
    ////
    //   GET_USER_ACCOUNTS
    case GET_USER_ACCOUNTS.PENDING:
      return {
        ...state,
        userAccounts: null,
        action: GET_USER_ACCOUNTS_ACTION_ID,
        actionError: null,
        actionState: 'working'
      };

    case GET_USER_ACCOUNTS.DONE:
      return {
        ...state,
        userAccounts: action.accounts,
        actionState: 'done'
      };

    case GET_USER_ACCOUNTS.FAILED:
      return {
        ...state,
        actionState: 'failed',
        actionError: action.error
      };


    ////
    //   GET_ACCOUNT_CHANNELS
    case GET_ACCOUNT_CHANNELS.PENDING:
      return {
        ...state,
        accountChannels: null,
        action: GET_ACCOUNT_CHANNELS_ACTION_ID,
        actionError: null,
        actionState: 'working'
      };

    case GET_ACCOUNT_CHANNELS.DONE:
      return {
        ...state,
        accountChannels: action.channels,
        actionState: 'done'
      };

    case GET_ACCOUNT_CHANNELS.FAILED:
      return {
        ...state,
        actionState: 'failed',
        actionError: action.error
      };


    ////
    //   SET_MSTEAMS_CONTEXT_TYPE
    case SET_MSTEAMS_CONTEXT_TYPE:
      return {
        ...state,
        msteamsContext: action.context,
        msteamsTheme: action.context.theme
      };


    ////
    //   SET_MSTEAMS_THEME
    case SET_MSTEAMS_THEME:
      return {
        ...state,
        msteamsTheme: action.theme
      };


    ////
    //  default
    default:
      return state;
  }
}
