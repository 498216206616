/*
 * actions
 */
// const SET_THEME_TYPE = '/ui/SET_THEME_TYPE';

/*
 * action creators
 */
/* export function setTheme(themeName) {
  return {type: SET_THEME_TYPE, themeName: themeName};
}
 */
/*
 * initial state
 */
const initialState = {
  // theme: null
}

/*
 * reducer
 */
export default function reducer(state = initialState, action){
  switch (action.type) {
    /* case SET_THEME_TYPE: {
      let theme = null;
      switch(action.themeName) {
        case 'dark':
          theme = 'teamsDark';
          break;
        case 'contrast':
          theme = 'teamsHighContrast';
          break;
        default:
          theme = 'teams';
          break;
      }
      return {
        ...state,
        theme: theme
      };
    } */

    default:
      return state;
  }
}
