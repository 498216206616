import { teamsTheme, teamsDarkTheme, teamsHighContrastTheme } from '@fluentui/react-northstar'


export function getMicrosoftTeamsTheme(theme) {
  switch(theme) {
    case 'dark':
      return teamsDarkTheme;
    case 'contrast':
      return teamsHighContrastTheme;
    default:
      return teamsTheme;
  }
}
