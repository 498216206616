import React from 'react';
import PropTypes from 'prop-types';
import { actionErrorType } from '@cocoom/cocoom-prop-types';

import { useTranslation } from 'react-i18next';

import { Alert } from '@fluentui/react-northstar';
import { ExclamationTriangleIcon } from '@fluentui/react-icons-northstar'

import styling from './styling';


const propTypes = {
  noCocoomPlatformFoundAlert: PropTypes.bool,
  accountWithoutChannel: PropTypes.bool,
  unauthorizedAccessAlert: PropTypes.bool,
  noUserFoundAlert: PropTypes.bool,
  technicalErrorAlert: PropTypes.bool,
  actionError: actionErrorType
}

export function AlertMessages({
  noCocoomPlatformFoundAlert = false,
  accountWithoutChannel = false,
  unauthorizedAccessAlert = false,
  noUserFoundAlert = false,
  technicalErrorAlert = false,
  actionError = null
}) {
  const [tr] = useTranslation();

  function getAlert({type, header, content}) {
    const spacing = styling.spacing(1);
    return (
      <Alert
        danger={type === 'danger'}
        warning={type === 'warning'}
        icon={<ExclamationTriangleIcon />}
        header={header}
        content={content}
        style={{paddingTop: spacing, paddingBottom: spacing}}
      />
    );
  }


  return (
    <React.Fragment>
      {noCocoomPlatformFoundAlert && getAlert({type: 'warning', header: tr('alerts.noCocoomPlatformFound'), content: tr('alerts.noCocoomPlatformFoundHint')})}

      {accountWithoutChannel && getAlert({type: 'danger', header: tr('alerts.accountWithoutChannel'), content: tr('alerts.accountWithoutChannelHint')})}

      {unauthorizedAccessAlert && getAlert({type: 'danger', header: tr('alerts.unauthorizedAccess'), content: tr('alerts.unauthorizedAccessHint')})}

      {noUserFoundAlert && getAlert({type: 'danger', header: tr('alerts.userNotFound'), content: tr('alerts.userNotFoundHint')})}

      {technicalErrorAlert && getAlert({type: 'danger', header: tr('alerts.technicalError'), content: `${actionError && actionError.statusCode !== 'n/a' ? ('code ' + actionError.statusCode + ' : ') : ''} ${actionError ? actionError.text : ''}`})}
    </React.Fragment>
  );
}

AlertMessages.propTypes = propTypes;
