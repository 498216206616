import React from 'react';
import {
  AUTHENTIFICATION_USECASE,
  authentificationUsecaseType
} from '../propTypes';
import { useSelector, useDispatch } from 'react-redux';

import { useTranslation } from 'react-i18next';
import * as microsoftTeams from "@microsoft/teams-js";
import {
  Button,
  Loader,
  // Text
} from '@fluentui/react-northstar';

import { CallToSignIn } from '../components/CallToSignIn';
import { CallToSignUp } from '../components/CallToSignUp';

import styling from '../components/styling';

import {
  CHECK_AUTHENTIFICATION_ACTION_ID,
  checkAuthentification,
  GET_ASSOCIATED_COCOOM_ACCOUNTS_ACTION_ID,
  getAssociatedCocoomAccounts,
  SIGNOUT_ACTION_ID,
  signOut
} from '../reducers/auth'


const REDIRECTION_TO_PAGE_PARAM = 'next';

const propTypes = {
  usecase: authentificationUsecaseType,
  children: undefined
}

export function AuthenticationProtectedContent({usecase, children}) {
  const [tr] = useTranslation();
  const authState = useSelector(state => state.auth);
  const cocoomState = useSelector(state => state.cocoom);
  const configurationState = useSelector(state => state.configuration);
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (authState.isUserAuthentified === null) {
      // alert('MSTEAMS | Check auth');
      dispatch(checkAuthentification());
    }

    if (authState.isUserAuthentified === false && cocoomState.msteamsContext.tid) {
      dispatch(getAssociatedCocoomAccounts({azureTenantId: cocoomState.msteamsContext.tid, azureUserEmail: cocoomState.msteamsContext.loginHint}));
    }
  }, [dispatch, authState.isUserAuthentified, cocoomState.msteamsContext.tid, cocoomState.msteamsContext.loginHint]);


  function redirectToRequestedPage() {
    if (window.location.search && window.location.search.indexOf(REDIRECTION_TO_PAGE_PARAM) > 0) {
      const urlParams = new URLSearchParams(window.location.search);
      const requestedPage = urlParams.get(REDIRECTION_TO_PAGE_PARAM);
      window.location.replace(requestedPage);
    }
  }


  function handleAuthenticationCallback() {
    if (usecase === AUTHENTIFICATION_USECASE.CONFIGURABLE_TAB) {
      redirectToRequestedPage();
      return;
    }

    dispatch(checkAuthentification());
  }


  function getEncodedMsTeamsContext() {
    const data = {
      tid: cocoomState.msteamsContext.tid,
      uid: cocoomState.msteamsContext.userObjectId,
      gid: cocoomState.msteamsContext.groupId,
      l: cocoomState.msteamsContext.loginHint,
      lg: cocoomState.msteamsContext.locale.substring(0, 2)
    };

    return btoa(unescape(encodeURIComponent(JSON.stringify(data))));
  }

  function handleAssociateCocoomAccount() {
    microsoftTeams.authentication.authenticate({
      url: configurationState.associateToTeamsPageUrl + `?d=${getEncodedMsTeamsContext()}`,
      width: 450,
      height: 650,
      failureCallback: handleAuthenticationCallback, //(reason) => {/* console.log('FAILURE', reason) */},
      successCallback: handleAuthenticationCallback
    });
  }

  function handleConnectToCocoom(forceEnterCredentials = false) {
    let fromSingleAccountQueryPart = '';
    if (authState.associatedCocoomAccounts && authState.associatedCocoomAccounts.length === 1) {
      fromSingleAccountQueryPart = `from=${authState.associatedCocoomAccount.url}&next=${authState.associatedCocoomAccount.url}`;
    }

    let forceEnterCredentialsQueryPart = '';
    if (forceEnterCredentials) {
      forceEnterCredentialsQueryPart = `&prompt=true`;
    }

    if (fromSingleAccountQueryPart) {
      fromSingleAccountQueryPart += '&';
    }

    microsoftTeams.authentication.authenticate({
      url: configurationState.signInPageUrl + `?${fromSingleAccountQueryPart}d=${getEncodedMsTeamsContext()}${forceEnterCredentialsQueryPart}`,
      width: 450,
      height: 650,
      failureCallback: handleAuthenticationCallback, // (reason) => { console.log('FAILURE', reason); },
      successCallback: handleAuthenticationCallback
    });
  }


  function handleCreateCocoomAccount() {
    let campaignLanguage = cocoomState.msteamsContext.locale.substring(0, 2);
    if (campaignLanguage !== 'fr') {
      campaignLanguage = 'en';
    }
    microsoftTeams.authentication.authenticate({
      url: configurationState.signUpPageUrl + `?d=${getEncodedMsTeamsContext()}&utm_source=teams&utm_medium=app&utm_campaign=${campaignLanguage}`,
      width: 1024,
      height: 800,
      failureCallback: handleAuthenticationCallback, // (reason) => {/* console.log('FAILURE', reason) */},
      successCallback: handleAuthenticationCallback
    });
  }


  const uiState = {};
  if (usecase !== AUTHENTIFICATION_USECASE.SILENTLY_HIDE) {
    if ((authState.action === CHECK_AUTHENTIFICATION_ACTION_ID || authState.action === GET_ASSOCIATED_COCOOM_ACCOUNTS_ACTION_ID) && authState.actionState === 'working') {
      uiState.showConnectionLoader = true;
    }

    if (authState.action === SIGNOUT_ACTION_ID && authState.actionState === 'working') {
      uiState.showSignoutLoader = true;
    }

    if (authState.isUserAuthentified === false && authState.associatedCocoomAccount !== undefined && authState.actionState !== 'working') {
      if (authState.associatedCocoomAccount) {
        // alert('SHOW SIGN IN 1');
        uiState.showCallToSignIn = true;
      } else {
        uiState.showCallToSignUp = true;
      }
    } else if (authState.isUserAuthentified && authState.actionState !== 'working' && cocoomState.actionState !== 'working') {
      uiState.showSignoutButton = true;
    }
  }

  if (authState.isUserAuthentified) {
    uiState.showChildren = true;
  }


  return (
    <React.Fragment>
      {uiState.showConnectionLoader &&
        <div style={{position: 'absolute', left: 'calc(50% - 20px)', top: 'calc(50% - 20px)'}}>
          <Loader />
        </div>
      }

      {uiState.showCallToSignIn &&
        <CallToSignIn
          theme={cocoomState.msteamsTheme}
          usecase={usecase}
          onConnectToCocoom={handleConnectToCocoom}
        />
      }

      {uiState.showCallToSignUp &&
        <CallToSignUp
          theme={cocoomState.msteamsTheme}
          usecase={usecase}
          onCreateCocoomAccount={handleCreateCocoomAccount}
          onAssociateCocoomAccount={handleAssociateCocoomAccount}
        />
      }

      {uiState.showSignoutButton &&
        <div style={{ textAlign: 'right', margin: styling.spacing(1) }}>
          {cocoomState.msteamsContext && cocoomState.msteamsContext.loginHint &&
            <span
              style={{paddingRight: styling.spacing(1)}}
            >
              {
                tr('authentication.authenticationEmailUsage',
                  {email: authState.user ? authState.user.email : 'n/a'})
              }
            </span>
          }

          <Button
            size="small"
            content={tr('authentication.signout')}
            loading={uiState.showSignoutLoader}
            disabled={!authState.user}

            onClick={() => dispatch(signOut())}
          />
        </div>
      }

      <React.Fragment>
        {uiState.showChildren && children}
      </React.Fragment>

    </React.Fragment>
  );
}

AuthenticationProtectedContent.propTypes = propTypes;
