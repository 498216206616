const constants = {
  spacingDefault: 10
};

const defaultStyling = {
  color: {
    tonic: {
      main: '#175AE4',
      light:'#4F83ED',
      dark: '#2E3192'
    }
  },
  font: {
    title: '\'Source Sans Pro\', sans-serif'
  },
  spacing: (value) => {
    if (Number.isInteger(value)) {
      return value * constants.spacingDefault;
    }

    if (Array.isArray(value) && value.length === 2) {
      return `${value[0] * constants.spacingDefault}px ${value[1] * constants.spacingDefault}px`;
    }

    return constants.spacingDefault;
  }
};
export default defaultStyling;
