import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  actionStateType,
  actionErrorType
} from '@cocoom/cocoom-prop-types';

import { useSelector } from 'react-redux';

import { AlertMessages as AlertMessagesComponent } from '../components/AlertMessages';

import {
  GET_USER_ACCOUNTS_ACTION_ID,
  GET_ACCOUNT_CHANNELS_ACTION_ID
} from '../reducers/cocoom';

const propTypes = {
  action: PropTypes.string,
  actionState: actionStateType,
  actionError: actionErrorType
}

export function AlertMessages({action, actionState, actionError}) {
  const cocoomState = useSelector(state => state.cocoom);
  const [uiState, setUiState] = useState({});
  // const uiState = {};

  React.useEffect(() => {
    if (cocoomState.userAccounts && cocoomState.userAccounts.length === 0) {
      setUiState({
        ...uiState,
        noCocoomPlatformFoundAlert: true
      });
    }
  }, [uiState, cocoomState.userAccounts]);

  React.useEffect(() => {
    if (actionState === 'done' && action === GET_ACCOUNT_CHANNELS_ACTION_ID && (!cocoomState.accountChannels || cocoomState.accountChannels.length === 0)) {
      setUiState({
        ...uiState,
        accountWithoutChannel: true
      });
    }
  }, [uiState, actionState, action, cocoomState.accountChannels]);

  React.useEffect(() => {
    const localUiState = {};
    if (actionState === 'failed') {
      if (actionError && actionError.statusCode === 401) {
        localUiState.unauthorizedUserAlert = true;
      } else if (actionError && actionError.statusCode === 404 && action === GET_USER_ACCOUNTS_ACTION_ID) {
        localUiState.noUserFoundAlert = true;
      } else {
        localUiState.technicalErrorAlert = true;
      }

      setUiState({
        ...uiState,
        ...localUiState
      });
    }
  }, [uiState, actionState, action, actionError]);


  return (
    <AlertMessagesComponent
      noCocoomPlatformFoundAlert={uiState.noCocoomPlatformFoundAlert}
      accountWithoutChannel={uiState.accountWithoutChannel}
      unauthorizedAccessAlert={uiState.unauthorizedUserAlert}
      noUserFoundAlert={uiState.noUserFoundAlert}
      technicalErrorAlert={uiState.technicalErrorAlert}
      actionError={actionError}
    />
  );
}

AlertMessages.propTypes = propTypes;
