import React from 'react';
import PropTypes from 'prop-types';
import {
  AUTHENTIFICATION_USECASE,
  authentificationUsecaseType
} from '../propTypes';

import { useTranslation } from 'react-i18next';

import {
  Alert,
  Button,
  Text
} from '@fluentui/react-northstar';

import { PageTitle } from './PageTitle';

import styling from './styling';

const propTypes = {
  onConnectToCocoom: PropTypes.func,
  usecase: authentificationUsecaseType
}



export function CallToSignIn({usecase, onConnectToCocoom = () => {}, theme = 'default'}) {
  const [tr] = useTranslation();

  function getMessage() {
    switch(usecase) {
      case AUTHENTIFICATION_USECASE.CONFIGURABLE_TAB:
        return tr('callToSignIn.notConnectedConfigurableTabSignInHint');
      case AUTHENTIFICATION_USECASE.CONFIGURABLE_TAB_CONFIG:
        return tr('callToSignIn.notConnectedConfigurableTabConfigHint');
      case AUTHENTIFICATION_USECASE.CONFIGURABLE_TAB_DELETION:
        return tr('callToSignIn.notConnectedConfigurableTabDeletionHint');
      case AUTHENTIFICATION_USECASE.CONNECTOR_CONFIG:
        return tr('callToSignIn.notConnectedConnectorConfigHint');
      case AUTHENTIFICATION_USECASE.PERSONAL_TAB:
        return tr('callToSignIn.notConnectedPersonalTabHint');
      default:
        return null;
    }
  };

  const message = getMessage();
  if (message) {
    const uiState = {
      small: usecase === AUTHENTIFICATION_USECASE.CONFIGURABLE_TAB_CONFIG || usecase === AUTHENTIFICATION_USECASE.CONNECTOR_CONFIG
    };

    const paddingTopSpace = uiState.small ? '1em' : '2em';

    return (
      <div style={{textAlign: 'center'}}>
        <div style={{margin: styling.spacing(1), textAlign: 'left', maxWidth: 480, display: 'inline-block'}}>
          <PageTitle theme={theme} styles={{textAlign: 'center'}} small={uiState.small} text={tr('callToSignIn.title')} />

          <div style={{textAlign: 'center'}}>
            <img src="/signin.png" style={{width: uiState.small ? 180 : 220}} alt={tr('callToSignIn.notConnected')}/>
          </div>

          <div style={{paddingBottom: paddingTopSpace, textAlign: 'center'}}>
            <Alert content={message} style={{marginBottom: uiState.small ?30 : 48}} />

            <Text as="p" align="center">
              <Button primary onClick={() => onConnectToCocoom(false)}>
                {tr('callToSignIn.connectToCocoom')}
              </Button>
            </Text>

            <Button secondary onClick={() => onConnectToCocoom(true)}>
              {tr('callToSignIn.connectToCocoomForceEnterCredentials')}
            </Button>

            {/*
              <Text
              as="a"
              styles={{
                color: (theme === 'default') ? styling.color.tonic.dark : styling.color.tonic.light,
                cursor: 'pointer',
                textDecoration: 'underline'
              }}
              onClick={() => onConnectToCocoom(true)}
              >
              {tr('callToSignIn.connectToCocoomForceEnterCredentials')}
            </Text>
            */}
          </div>
        </div>

        <div style={{textAlign: 'center', paddingTop: paddingTopSpace, paddingBottom: styling.spacing(2)}}>
          <Text style={{whiteSpace: 'pre'}}>
            {tr('general.supportLink')}<br/>
          </Text>
          <Text
              as="a"
              styles={{
                color: (theme === 'default') ? styling.color.tonic.dark : styling.color.tonic.light,
                cursor: 'pointer',
                textDecoration: 'underline'
              }}
              onClick={() => window.location.href = 'mailto:support@cocoom.com'}
            >
              support@cocoom.com
            </Text>
        </div>
      </div>
    );
  }

  return null;
}

CallToSignIn.propTypes = propTypes;
