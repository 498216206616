import { useSelector } from 'react-redux';


 export function HelpFrame({locale}) {
  const cfgState = useSelector(state => state.configuration);

  if (locale && locale.substring(0, 2) === 'fr') {
    window.location.replace(cfgState.helpPageUrlFR ? cfgState.helpPageUrlFR : 'https://help.cocoom.com/fr/guide/msteams-app/overview.html');
  } else {
    window.location.replace(cfgState.helpPageUrlEN ? cfgState.helpPageUrlEN : 'https://help.cocoom.com/guide/msteams-app/overview.html');
  }

  return null;
}
