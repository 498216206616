import React from 'react';
import { useSelector } from 'react-redux';

import { AUTHENTIFICATION_USECASE } from '../propTypes';

import {
  Provider as FUIProvider
 } from '@fluentui/react-northstar';
 import {getMicrosoftTeamsTheme} from '../utils/getMicrosoftTeamsTheme';
import { AuthenticationProtectedContent } from '../containers/AuthenticationProtectedContent';


export function TabSignIn() {
  const cocoomState = useSelector(state => state.cocoom);

  return (
    <FUIProvider theme={getMicrosoftTeamsTheme(cocoomState.msteamsTheme)} style={{background: 'transparent'}}>
      <AuthenticationProtectedContent usecase={AUTHENTIFICATION_USECASE.CONFIGURABLE_TAB} />
    </FUIProvider>
  );
}
