import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import queryString from 'query-string';
import { Provider as FUIProvider } from '@fluentui/react-northstar';
 import {getMicrosoftTeamsTheme} from '../utils/getMicrosoftTeamsTheme';

 import { AuthenticationProtectedContent } from '../containers/AuthenticationProtectedContent';
 import { AlertMessages } from '../containers/AlertMessages';

import { getUserAccounts } from '../reducers/cocoom';
import { cookies } from '@cocoom/cocoom-utils/cookies';
import { AUTHENTIFICATION_USECASE } from '../propTypes';


export function PersonalTab({iframeId, embedPath}) {
  const MSTEAMS_SELECTED_ACCOUNT_URL_COOKIE = 'cc-msteams-selected-account-url';

  const cocoomState = useSelector(state => state.cocoom);
  const authState = useSelector(state => state.auth);

  const [noAccountAccess, setNoAccountAccess] = useState(false);

  const dispatch = useDispatch();

  React.useEffect(() => {
    if (authState.isUserAuthentified) {
      dispatch(getUserAccounts());
    }
  }, [dispatch, authState.isUserAuthentified]);

  React.useEffect(() => {
    if (cocoomState.userAccounts && cocoomState.userAccounts.length > 0) {
      const userAccountPreselection = cookies.get(MSTEAMS_SELECTED_ACCOUNT_URL_COOKIE);

      let requestedAccount;
      if (cocoomState.msteamsContext && cocoomState.msteamsContext.subEntityId) {
        const entityIdAndOptionalQuery = cocoomState.msteamsContext.subEntityId.split('?');
        if (entityIdAndOptionalQuery.length > 1) {
          const entityIdParams = queryString.parse(entityIdAndOptionalQuery[1]);
          requestedAccount = entityIdParams.domain;
        }
      }

      // console.log('***********************************************');
      // console.log('    - cocoomState.msteamsContext.subEntityId = ' + cocoomState.msteamsContext.subEntityId);
      // console.log('    - requestedAccount = ' + requestedAccount);

      // let selectedAccount = cocoomState.userAccounts[0];
      let selectedAccount;
      for (const account of cocoomState.userAccounts) {
        if (requestedAccount && account.url === requestedAccount) {
          selectedAccount = account;
          break;
        }

        if (userAccountPreselection && userAccountPreselection.startsWith(account.url)) {
          selectedAccount = account;
        }
      }

      // If no account found, use the first one in the list to redirect the signed-in user (only if no specific account was requested)
      if (!selectedAccount && !requestedAccount) {
        selectedAccount = cocoomState.userAccounts[0];
      }
      if (selectedAccount && requestedAccount && selectedAccount.url !== requestedAccount) {
        selectedAccount = null;
      }

      if (selectedAccount) {
        setNoAccountAccess(false);
        const redirection = selectedAccount.url + embedPath;

        // Persist the selected account URL choice
        cookies.set(MSTEAMS_SELECTED_ACCOUNT_URL_COOKIE, selectedAccount.url, {secure: true, sameSite: 'None', expire: Infinity});

        // console.log('    - selectedAccount = ' + selectedAccount.url);
        // console.log('    - coookie = ' + cookies.get(MSTEAMS_SELECTED_ACCOUNT_URL_COOKIE));
        // console.log('    - REDIRECTION = ' + redirection);
        // console.log('    - embed path = ' + embedPath);
        // console.log('***********************************************');

        window.location.replace(redirection);

      } else {
        // console.log('    - no account access; account requested = ' + requestedAccount);
        // console.log('***********************************************');
        setNoAccountAccess(true);
      }
    }
  }, [cocoomState.userAccounts, embedPath, cocoomState.msteamsContext]);

  const uiState = {};
  if (cocoomState.actionState === 'working') {
    uiState.loader = true;
  }

  return (
    <FUIProvider theme={getMicrosoftTeamsTheme(cocoomState.msteamsTheme)} style={{background: 'transparent'}}>
      <AuthenticationProtectedContent usecase={AUTHENTIFICATION_USECASE.PERSONAL_TAB}>
        <React.Fragment>
        {noAccountAccess &&
          <AlertMessages
            actionState='failed'
            actionError={{statusCode: 401}}
          />
        }
        {!noAccountAccess && cocoomState.actionError &&
          <AlertMessages
            actionState='failed'
            actionError={cocoomState.actionError}
          />
        }
        </React.Fragment>
      </AuthenticationProtectedContent>
    </FUIProvider>
  );
}
