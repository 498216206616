import { combineReducers } from 'redux';
import auth from './auth';
import cocoom from './cocoom';
import configuration from './configuration';
import ui from './ui';

export default combineReducers({
  auth,
  cocoom,
  configuration,
  ui
})
