import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { useSelector, useDispatch } from 'react-redux';

import { CocoomChannelSelector as CocoomChannelSelectorComponent } from '../components/CocoomChannelSelector';

import {
  getUserAccounts,
  getAccountChannels
} from '../reducers/cocoom';

const propTypes = {
  onChannelSelection: PropTypes.func,
}

export function CocoomChannelSelector({
  onChannelSelection = (channel) => console.log(`${channel.ref} of ${channel.account.id} Cocoom account was selected.`),
}) {
  const cocoomState = useSelector(state => state.cocoom);
  const dispatch = useDispatch();
  const [cocoomAccount, setCocoomAccount] = useState(null);

  React.useEffect(() => {
    dispatch(getUserAccounts());
  }, [dispatch, cocoomState.msteamsContext.userObjectId]);

  React.useEffect(() => {
    if (Array.isArray(cocoomState.userAccounts) && cocoomState.userAccounts.length === 1) {
      const accountId = cocoomState.userAccounts[0].id;
      setCocoomAccount({id: accountId, name: cocoomState.userAccounts[0].name});
      dispatch(getAccountChannels(accountId));
    }
  }, [dispatch, cocoomState.userAccounts]);

  function handleAccountSelection(account) {
    dispatch(getAccountChannels(account.id));
    setCocoomAccount(account);
    onChannelSelection(null);
  }

  function handleChannelSelection(channel) {
    onChannelSelection({
      ...channel,
      account: cocoomAccount
    });
  }

  return (
    <CocoomChannelSelectorComponent
      actionState={cocoomState.actionState}
      userAccounts={cocoomState.userAccounts}
      selectedAccount={cocoomAccount}
      accountChannels={cocoomState.accountChannels}
      onAccountSelection={handleAccountSelection}
      onChannelSelection={handleChannelSelection}
    />
  );
}

CocoomChannelSelector.propTypes = propTypes;
