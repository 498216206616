import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { useTranslation } from 'react-i18next';

import {
  Provider as FUIProvider
 } from '@fluentui/react-northstar';
import {getMicrosoftTeamsTheme} from '../utils/getMicrosoftTeamsTheme';

import { PageTitle } from '../components/PageTitle';

import styling from '../components/styling';


export function WelcomePage() {
  const [tr] = useTranslation();
  const cocoomState = useSelector(state => state.cocoom);
  const [theme, setTheme] = useState({});

  useEffect(() => {
    const theme = getMicrosoftTeamsTheme(cocoomState.msteamsTheme);
    setTheme(theme);
  }, [cocoomState.msteamsTheme]);

  return (
    <FUIProvider theme={theme} style={{background: 'transparent'}}>
      <div style={{textAlign: 'center'}}>
        <div style={{margin: styling.spacing(1), textAlign: 'left', maxWidth: 480, display: 'inline-block'}}>
          <PageTitle theme={theme} styles={{textAlign: 'center'}} text={tr('welcomePage.title')} />
        </div>
      </div>
    </FUIProvider>
  );
}
