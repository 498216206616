

/*
 * actions
 */
const SET_CONFIGURATION = '/cocoom/SET_CONFIGURATION';


/*
 * action creators
 */
export function setConfiguration(configuration) {
  return {type: SET_CONFIGURATION, configuration: configuration};
}

/*
 * initial state
 */
const initialState = {}

/*
 * reducer
 */
export default function reducer(state = initialState, action) {
  switch (action.type) {

    ////
    //  SET_CONFIGURATION
    case SET_CONFIGURATION:
      return {
        ...state,
        ...action.configuration
      };

    ////
    //  default
    default:
      return state;
  }
}
