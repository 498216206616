import React from 'react';

export function Emoji({symbol, label}) {
  return (
    <span
        className="emoji"
        role="img"
        aria-label={label ? label : ""}
        aria-hidden={label ? "false" : "true"}
    >
        {symbol}
    </span>
  );
}
