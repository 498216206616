import React from 'react';
import PropTypes from 'prop-types';
import {
  accountChannelListType,
  actionStateType,
  basicAccountType,
  userAccountBasicListType
} from '@cocoom/cocoom-prop-types';

import { useTranslation } from 'react-i18next';

import { Dropdown } from '@fluentui/react-northstar';
import { Loader } from '@fluentui/react-northstar';
import { Text } from '@fluentui/react-northstar';

const propTypes = {
  actionState: actionStateType,
  userAccounts: userAccountBasicListType,
  selectedAccount: basicAccountType,
  accountChannels: accountChannelListType,
  onAccountSelection: PropTypes.func,
  onChannelSelection: PropTypes.func
}

export function CocoomChannelSelector({
  actionState = 'idle',
  userAccounts = [],
  selectedAccount = null,
  accountChannels = [],
  onAccountSelection = (account) => console.log(`${account.id} Cocoom account was selected.`),
  onChannelSelection = (channel) => console.log(`${channel.ref} was selected.`),
}) {
  const [tr] = useTranslation();

  function handleAccountSelection(e, item) {
    onAccountSelection({id: item.value.id, name: item.value.header});
  }

  function handleChannelSelection(e, item) {
    onChannelSelection({
      ref: item.value.id,
      name: item.value.header,
      url: item.value.url,
      embedUrl: item.value.embedurl
    });
  }

  const uiState = {};
  if (actionState === 'working') {
    uiState.loader = true;
  }

  if (userAccounts && userAccounts.length > 1) {
    uiState.platformSelector = true;
  }

  if (accountChannels && accountChannels.length > 0 && selectedAccount) {
    uiState.channelSelector = true;
  }

  return (
    <React.Fragment>
      {uiState.platformSelector && (
        <div>
        <Text as="div">{tr('channelSelector.cocoomPlatform')}</Text>
        <Dropdown
          placeholder={tr('channelSelector.cocoomPlatformHint')}
          items={userAccounts.map((item => { return {header: item.name, id: item.id, selected: selectedAccount && (item.id === selectedAccount.id)};}))}
          value={selectedAccount && selectedAccount.name}
          onChange={handleAccountSelection}
        />
      </div>
      )}
      {uiState.channelSelector && (
        <div>
          <Text as="div">{tr('channelSelector.cocoomChannel')}</Text>
          <Dropdown
            placeholder={tr('channelSelector.cocoomChannelHint')}
            items={accountChannels.map((item => { return {header: item.name, id: item.ref, url: item.url, embedurl: item.embedUrl};}))}
            onChange={handleChannelSelection}
          />
        </div>
      )}
      {uiState.loader && (
        <div style={{position: 'absolute', left: 'calc(50% - 20px)', top: 'calc(50% - 20px)'}}>
          <Loader />
        </div>
      )}
    </React.Fragment>
  );
}

CocoomChannelSelector.propTypes = propTypes;
